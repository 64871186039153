import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header style={{ background: 'transparent', padding: '10px', marginBottom: '20px' }}>
      <nav>
        <ul style={{ listStyle: 'none', display: 'flex', justifyContent: 'space-around' }}>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/projects">Projects</Link></li>
          <li><Link to="/blog">Blog</Link></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
