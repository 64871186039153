import projectEdgeImage from './images/project-edge.jpeg';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex'; // may not need InlineMath

const Formula = ({ children }) => (
  <div className="my-4 overflow-x-auto">
    <BlockMath>{children}</BlockMath>
  </div>
);

const metadata = {
  id: 2,
  title: 'Finding Your Edge: A Quantitative Approach to Project Choices',
  date: '2024-10-31',
  excerpt: 'Explore how to identify your unique strengths for impactful projects.',
  featuredImage: projectEdgeImage,
};

const BlogPost = () => {
  return (
    <article className="prose prose-gray mx-auto dark:prose-invert">
      <h1>{metadata.title}</h1>
      <div className="content">
        <figure>
          <img 
            src={projectEdgeImage} 
            alt="Graphic showing quantitative approach to finding project edge" 
            className="w-full h-auto rounded-lg"
          />
          <figcaption>Created by AI, the typos are cute lol</figcaption>
        </figure>
        <p>
          In today’s competitive landscape, identifying your unique edge in business and life is essential for making impactful project choices. A structured, quantitative approach can help you navigate various opportunities effectively. Here’s how to evaluate your strengths and market potential systematically.
        </p>
        <p>
          None of these formulae are from emperical evidence, purely a mental model so far. Score each variable between 1 to 5.
        </p>

        <h2>Key Factors to Consider</h2>

        <h3>1. Motivational Alignment</h3>
        <p>
          Consider your personal enjoyment of the project, the societal impact it aims to achieve, and the negative aspects of competition in the project. Weight these factors to reflect their significance to your satisfaction and fulfilment.
        </p>
        <Formula>
          {'MotivationScore(e,i,c) = e^2 + i - c'}
        </Formula>
        <ul>
          <li><strong>e:</strong> Personal Enjoyment score – A measure of your enjoyment and passion for the project.</li>
          <li><strong>i:</strong> Impact and Respect score – A measure of the project's potential to create a positive social impact and gain recognition. Assume not a sadist.</li>
          <li><strong>c:</strong> Competitive Element score – A measure of how competitive the project is within its market, with a higher score indicating greater competition.</li>
        </ul>

        <h3>2. Skills and Competencies</h3>
        <p>
          Assess your technical skills and adaptability. The Learning Speed score will be multiplied with Technical Skills scores.
        </p>
        <Formula>
          {'SkillsScore(t,l) = t \\cdot l'}
        </Formula>
        <ul>
          <li><strong>t:</strong> Technical Skills score – A measure of your proficiency in specific technical areas relevant to your project.</li>
          <li><strong>l:</strong> Learning Speed score – A measure of how quickly you can acquire new skills or knowledge.</li>
        </ul>

        <h3>3. Market Trend Alignment</h3>
        <p>
          Analyse emerging demand, market longevity, and niche potential. Use multiplication to highlight how interdependent these factors are, ensuring a robust product-market fit.
        </p>
        <Formula>
          {'MarketTrendScore(d,l,n) = \\frac{d \\cdot lo \\cdot n}{3}'}
        </Formula>
        <ul>
          <li><strong>d:</strong> Emerging Demand score – A measure of the current and future demand for the project's output.</li>
          <li><strong>lo:</strong> Longevity score – A measure of the project's potential sustainability and long-term viability in the market.</li>
          <li><strong>n:</strong> Niche Potential score – A measure of how well the project can carve out a unique space within its market.</li>
        </ul>

        <h3>4. Cultural Fit and Identity</h3>
        <p>
          Your background can offer unique insights into specific markets. Evaluate your Cultural Insight, Community Connection, and Values Alignment, using geometric means to emphasise the importance of balance across these areas.
        </p>
        <Formula>
          {'CulturalFitScore(ci,cc,va) = \\sqrt{ci \\cdot cc \\cdot va}'}
        </Formula>
        <ul>
          <li><strong>ci:</strong> Cultural Insight score – A measure of your understanding of the cultural context and nuances relevant to the project.</li>
          <li><strong>cc:</strong> Community Connection score – A measure of your relationships and connections within the community related to the project.</li>
          <li><strong>va:</strong> Values Alignment score – A measure of how closely your personal values align with the project's goals and mission.</li>
        </ul>

        <h2>Building the Model</h2>
        <p>
          To quantify these factors, combine them into an <strong>Overall Edge Score</strong>:
        </p>
        <Formula>
          {'OverallEdgeScore = MotivationScore + SkillsScore + MarketTrendScore + CulturalFitScore'}
        </Formula>
        <p>
          This formula provides a holistic view of how well your project aligns with market needs and your personal strengths. Roughly results in a score between 0 to 100, like a percentage. But true bounds are between -1 to 121, yes it's possible to be negative!
        </p>

        <h2>Conclusion</h2>
        <p>
          By applying this quantitative model, you can make informed decisions that align with your skills, motivations, and market trends. This structured approach will guide you toward fulfilling and impactful projects, ensuring that your unique edge shines through in everything you pursue. Whether exploring new opportunities or refining existing ones, remember that your personal insights and experiences are invaluable assets in today’s dynamic world. The act of writing this down in itself helps me think about what is valuable to me and their relationships.
        </p>
      </div>
    </article>
  );
};

// Export both the metadata and the component
export default {
  ...metadata,
  component: BlogPost
};
