const metadata = {
  id: 3,
  title: 'Values and Heirarchy',
  date: '2024-11-02',
  excerpt: 'What is value and how to climb to the apex',
};

const BlogPost = () => {
  return (
    <article className="prose prose-gray mx-auto dark:prose-invert">
      <h1>{metadata.title}</h1>
      <div className="content">
        <p>
          What do I value and how does it change depending on the existing environment and heirarchy.
        </p>
      </div>
    </article>
  );
};

// Export both the metadata and the component
export default {
  ...metadata,
  component: BlogPost
};
