const metadata = {
  id: 1,
  title: 'AI displaying macronutrients of a meal',
  date: '2024-11-01',
  excerpt: 'A quick way for people to track their food intake by protein, carbs, fats',
  //featuredImage: igCollectionImage,
};

const ProjectsPost = () => {
  return (
    <article className="prose prose-gray mx-auto dark:prose-invert">
      <h1>{metadata.title}</h1>
      <div className="content">
        <p>
          I really enjoy classic physiques and know that lifting weights is only a small factor of achieving this look. Diet and nutrition is the most important thing to consider in order to get a body like this:
        </p>
        <p>
          Diets are not very fun so I loosely use a IIFYM diet which I've simplified with only two goals. Firstly, hit 150g protein per day. Secondly, limit carbs and fat - limit meaning only one meal a day with a heavy amount of carbs. It's not very scientific but I find it works for me because I know how I feel after eating things and can roughly estimate from looking at a meal what the macronutrients are. It also helps that I cook most of my meals so the routine makes things fairly predictable. Three meals a day of around 50g protein each. Mornings is no carbs, lunch is normal meals, and dinner is a big meal.
        </p>
        <p>
          But I know at the start of the fitness jourey it can be overwhelming for anyone new to nutrition to quickly see what the relevant macros are for each item. You can of course check labels and measure the weights and g/100g of each macro but I don't think it needs to be that precise unless you have aims of being a bodybuilder.
        </p>
        <p>
          So, wouldn't it be sweet if we had more accurate ways of estimating macros e.g. using your phone to take an image and scan your meal in realtime? I see some Apps have made this possible but the accuracy is not quite there yet. Also, I'm not sure if this is a pain problem for those who want to become healthier or fitter so there is only a little opportunity here but I would like to expand my computer vision skills doing this project.
        </p>
      </div>
    </article>
  );
};

// Export both the metadata and the component
export default {
  ...metadata,
  component: ProjectsPost
};
