import React from 'react';

function HomePage() {
  return (
    <div>
      <h1>Hey! I’m Han</h1>
      <p>
        I’ll be sharing my thoughts and personal projects here, partly to keep myself accountable and hopefully to inspire you too. If you’re like me, you’ve got loads of ideas that sometimes don’t materialise because there’s never enough time. So, this website is both a journal and a bit of an accountability tracker. Eventually, one of these ideas will take off - let’s see which one!
      </p>
      <p>
        Most of my work experience has been as an Analyst, mainly in Data. Coding has always been my favourite part because, for me, it’s more than just logic and structure. Coding’s about making a huge impact by building things with just a computer and the internet.
      </p>
      <p>
        Looking back, my career choices have been a bit <a href="https://www.urbandictionary.com/define.php?term=Yolo" target="_blank" rel="noopener noreferrer">YOLO</a>. I took a Gap Year funded by online Poker before starting University, jumped from an Investment Bank in London to volunteering in Kenya, then did a bit of Digital Nomading, went back to Corporate, paused to explore China, where I was born, and taught Economics there for a while. Finally, I returned to London during Covid. My choices have been very <a href="https://en.wikipedia.org/wiki/Greedy_algorithm" target="_blank" rel="noopener noreferrer">in the moment</a> - whatever seemed exciting right then.
      </p>
      <p>
        Now that I’m in my thirties, I want a bit more. These short adventures were fun, but a long-term mission sounds cooler. I still want the freedom of remote work, so my projects will be indie-hacker style. After seeing how AI and automation changed poker, trading, and business, I’m about 90% sure I’ll focus on AI. It seems like the best way to scale as an individual in a digital world - just look at the biggest companies by market cap.
      </p>
      <p>
        So… time to build. The plan? Find problems, distribution channels, monetisation models, and finally, create the solution.
      </p>
      <p><strong>Industries I’m interested in (non-exhaustive):</strong></p>
      <ul>
        <li>Health & Fitness</li>
        <li>Education</li>
        <li>Travel</li>
        <li>Finance</li>
      </ul>
      <h2>Goal: 1 project per month.</h2>
    </div>
  );
}

export default HomePage;