import React from 'react';
import { Link } from 'react-router-dom';

// Dynamically import all blog posts
const importAll = (r) => {
  let posts = [];
  r.keys().forEach((key) => {
    // Only process index.js files
    if (key.endsWith('index.js')) {
      const post = r(key);
      // Extract the folder name without the date prefix
      const fullSlug = key.split('/')[1]; // Gets the folder name after 'posts/'
      const slug = fullSlug.replace(/^\d{4}-\d{2}-\d{2}-/, ''); // Remove date prefix
      posts.push({
        ...post.default,
        slug,
        fullSlug, // Keep the full slug with date for internal use if needed
      });
    }
  });
  return posts;
};

const posts = importAll(require.context('../projectsposts', true, /index\.js$/));

function ProjectsPage() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-4">My Projects</h1>
      <h2 className="text-2xl font-semibold mb-6">Projects Posts:</h2>
      
      <div className="space-y-4">
        {posts.map((post) => (
          <article key={post.slug} className="border-b pb-4">
            <Link 
              to={`/projects/${post.slug}`}
              className="block hover:text-primary transition-colors"
            >
              <h3 className="text-xl font-semibold">{post.title}</h3>
              {post.excerpt && (
                <p className="text-muted-foreground mt-1">
                  {post.excerpt}
                </p>
              )}
            </Link>
          </article>
        ))}
      </div>

      {posts.length === 0 && (
        <p className="text-muted-foreground">No projects posts found.</p>
      )}
    </div>
  );
}

export default ProjectsPage;