import igCollectionImage from './images/ig-collection.jpeg';

const metadata = {
  id: 1,
  title: 'Bringing Visions to Life',
  date: '2024-10-29',
  excerpt: 'Explore the journey of turning creative ideas into reality.',
  featuredImage: igCollectionImage,
};

const BlogPost = () => {
  return (
    <article className="prose prose-gray mx-auto dark:prose-invert">
      <h1>{metadata.title}</h1>
      <div className="content">
        <p>
          The world is full of inspiring images, and they motivate us to create. I've always liked art and illustration but never fully committed to learning how to draw. Since I didn’t see art as a viable career path, I kept it as a hobby. Here are some things I've enjoyed sketching:
        </p>
        <figure>
          <img 
            src={igCollectionImage} 
            alt="Some doodles from 2016" 
            className="w-full h-auto rounded-lg"
          />
          <figcaption>Some doodles from 2016</figcaption>
        </figure>
        <p>
          As a kid, I entered creative competitions like designing posters for the City Council. I even won a bike in a Heinz Ketchup comic contest. Design classes at school were always so fun making products with my hands and physical tools but I couldn’t see how that career path would be lucrative. Later in my twenties, I picked up Adobe Illustrator and started making print-on-demand designs like T-shirts and hoodies. Earning passive income from royalties was satisfying but the excitement faded when sales dropped because of copycat designs.
        </p>
        <p>
          Now, with AI image generation, I feel renewed enthusiasm. It allows me to create relevant visuals quickly without spending years on techniques. I look forward to integrating it into my work. Once I reach my goal of $50K in monthly recurring revenue I might dive back into art for fun, possibly exploring street art, inspired by my recent visit to the Leake Street Tunnels.
        </p>
      </div>
    </article>
  );
};

// Export both the metadata and the component
export default {
  ...metadata,
  component: BlogPost
};
