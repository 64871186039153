import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import HomePage from './pages/HomePage';
import BlogPage from './pages/BlogPage';
import BlogPost from './pages/BlogPost';
import ProjectsPage from './pages/ProjectsPage';
import ProjectsPost from './pages/ProjectsPost';
import NotFound from './pages/NotFound'; // Import NotFound component

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:slug" element={<BlogPost />} /> {/* Dynamic route for blog posts */}
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/projects/:slug" element={<ProjectsPost />} />
          <Route path="*" element={<NotFound />} /> {/* Fallback for unknown routes */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
