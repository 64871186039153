import React from 'react';
import { useParams } from 'react-router-dom';

// Dynamically import all blog posts
const importAll = (r) => {
  let posts = [];
  r.keys().forEach((key) => {
    // Only process index.js files
    if (key.endsWith('index.js')) {
      const post = r(key);
      // Extract the folder name without the date prefix
      const fullSlug = key.split('/')[1]; // Gets the folder name after 'posts/'
      const slug = fullSlug.replace(/^\d{4}-\d{2}-\d{2}-/, ''); // Remove date prefix
      posts.push({
        ...post.default,
        slug,
        fullSlug, // Keep the full slug with date for internal use if needed
      });
    }
  });
  return posts;
};

const posts = importAll(require.context('../projectsposts', true, /index\.js$/));

function ProjectsPost() {
  const { slug } = useParams();

  // Find the blog post by slug
  const post = posts.find(post => post.slug === slug);

  if (!post) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold text-red-600">Projects post not found!</h2>
      </div>
    );
  }

  const PostComponent = post.component;

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <article className="prose prose-gray mx-auto dark:prose-invert">
        <header className="mb-8">
          <h1 className="text-4xl font-bold mb-2">{post.title}</h1>
          {/* If you still want to display the date, keep this section */}
          {post.date && (
            <time className="text-muted-foreground">
              {new Date(post.date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </time>
          )}
        </header>
        
        {PostComponent && <PostComponent />}
      </article>
    </div>
  );
}

export default ProjectsPost;
